import React from 'react';

// TableList component to display a list of SQLite table names
const TableList = ({ tables, setFocusedTable }) => {
  return (
    <div className="tables-list">
      {tables?.map((table, i) => {
        return (<div className="tables-list__item" key={table.name}><button onClick={() => setFocusedTable(table)}>{table.name}</button></div>)
      })}
    </div>
  );
};

export default TableList;