import './App.css';
import { useState, useEffect } from 'react';

import TableList from './TableList'
import DataDisplay from './DataDisplay';

const App = () => {

  const defaultQuery = "SELECT * FROM users";

  const [query, setQuery] = useState(defaultQuery);
  const [response, setResponse] = useState("");
  const [tables, setTables] = useState(null);
  const [focusedTable, setFocusedTable] = useState(null);
  const [columns, setColumns] = useState(null);
  const [content, setContent] = useState(null);

  useEffect(() => {
    getTables();
  }, []);

  useEffect(() => {
    if (focusedTable) {
      getTableInfo();
    }
  }, [focusedTable]);

  useEffect(() => {
    if (columns) {
      getTableContent(); 
    }
  }, [columns]);

  useEffect(() => {
    if (response.success) {
      setTables(null);
      getTables();

      if (response.returnedData.length > 0) {
        // display response in main view
        setContent(response.returnedData);
        getColumnsFromResponse();
      } else {
        getTableInfo();
        getTableContent();
      }
    }
  }, [response]);

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };
  
  const submitQuery = () => {
    var formData = new FormData();
    formData.append("query", query);
    postJSON(formData);
    setResponse("");
  };
  
  const postJSON = (donnees) => {
    fetch("https://bdd.infodoo.com/public/query", {
      method: "POST",
      body: donnees
    }).then(r => r.json()).then(body => {
      setResponse(body);
    });
  };

  const getColumnsFromResponse = () => {
    setColumns(Object.keys(response.returnedData[0]).map((column) => { return { name : column }}));
  };

  const getTables = () => {
    fetch("https://bdd.infodoo.com/public/tables", {
      method: "GET"
    }).then(r => r.json())
    .then(body => {
      setTables(body);
    });
  };

  const getTableInfo = () => {
    fetch("https://bdd.infodoo.com/public/table?name=" + focusedTable?.name, {
      method: "GET"
    }).then(r => r.json())
    .then(body => {
      setColumns(body);
    });
  };

  const getTableContent = () => {
    if (!focusedTable) {
      return;
    }

    fetch("https://bdd.infodoo.com/public/table-content?name=" + focusedTable?.name, {
      method: "GET"
    }).then(r => r.json())
    .then(body => {
      setContent(body);
    });
  };

  return (
    <div className="App">
      <div className="app__header">
        <img alt="Infodoo Lite" src="infodoo_lite_logo.svg"/>
        <h1 className="title">infodoo Lite</h1>
      </div>
      <div className="tables-context">
        <TableList tables={ tables } setFocusedTable={ setFocusedTable }/>
        <DataDisplay columns={ columns } content={ content }/>
      </div>
      <textarea id="request" name="request" rows="5" cols="33" defaultValue={defaultQuery} onChange={handleQueryChange} />
      <button type="button" onClick={submitQuery}>Execute</button>
      <h3>Réponse :</h3>
      <div className="code">
        {response ? JSON.stringify(response.success) : ""}
      </div>
    </div>
  );
};

export default App;