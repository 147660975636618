import React from 'react';

// DataDisplay component to display the content of a table or query result
const DataDisplay = ({ columns, content }) => {
  return (
    <div className="column-wrapper">
      <div className="column-content-wrapper">
        <div className="column-header-wrapper">
          {columns?.map((column, i) => {
            return (
              <div className="column-header" key={i}>
                {column.name}
              </div>
            );
          })}
        </div>
        <div className="column-content-display">
          {columns?.map((column, i) => {
            return (
              <div className='column' key={i}>
                {content?.map((row, i) => {
                  return (<div className="row-item" key={i}>{row[column.name]}</div>)
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DataDisplay;